import Icons from 'Icons';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyDrawer from 'components/MyDrawer/MyDrawer';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { WorkItemStatusDisplay } from 'features/workOrders/enums/WorkItemStatus';
import workOrdersApi from 'features/workOrders/workOrders.api';
import React, { useEffect, useMemo } from 'react';
import { isNone } from 'utils/helpers';
import WorkItemStatusBadge from '../WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStepList from '../WorkItemStepList/WorkItemStepList';
import './WorkItemDrawer.scss';

export default function WorkItemDrawer({
    workItemId,
    tuid,
    close,
}: {
    workItemId: string;
    tuid?: string;
    close?: () => void;
}) {
    const query = workOrdersApi.useWorkItemDetailQuery(workItemId);
    const model = query.data;

    /** Close the drawer whenever user clicks out of the drawer
     * Excluding when they click on another row in the line items table within SalesOrderDetailModal
     */
    useEffect(() => {
        function handleClick(e: MouseEvent) {
            const tableElem = document.querySelector('.WorkOrderDetailModal__WorkItemsTable');
            const workOrderModalElem = document.querySelector('.WorkOrderDetailModal');
            const target = e.target as Element;

            // close the drawer when clicking outside the drawer (i.e anywhere on the parent modal)
            // except for the line items table
            if (workOrderModalElem?.contains(target) && !tableElem?.contains(target)) {
                close?.();
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClick);

        // cleanup when unmounting
        return () => document.removeEventListener('click', handleClick);
    }, [close]);

    /** Map labels record to array for easy display */
    const labelsArray = useMemo(() => {
        if (!model) {
            return [];
        }
        return Object.entries(model.labels).map(([key, val]) => {
            return {
                name: key,
                value: isNone(val) ? '' : `${val}`,
            };
        });
    }, [model]);

    /** Map options record to array for easy display */
    const optionsArray = useMemo(() => {
        if (!model) {
            return [];
        }
        return Object.entries(model.options).map(([key, val]) => {
            return {
                name: key,
                value: isNone(val) ? '' : `${val}`,
            };
        });
    }, [model]);

    return (
        <MyDrawer
            className="WorkItemDrawer"
            onClose={close}
            anchor="right"
            hideBackdrop={true}
            isLoading={query.isFetching}
            isError={query.isError}
            header={
                <>
                    <MyButton
                        className="WorkItemDrawer__Header__BackButton"
                        IconRight={Icons.ChevronLeft}
                        buttonType="None"
                        onClick={close}
                    />
                    <PageHeader
                        className="WorkItemDrawer__PageHeader"
                        title="Work Item"
                        titleContext={`${tuid}`}
                    />
                </>
            }
        >
            {model && (
                <>
                    {model.isCancelled && (
                        <MessagePanel
                            messageType="warning"
                            className="WorkItemDrawer__CancelledMessage"
                        >
                            This order has been cancelled
                        </MessagePanel>
                    )}
                    <PropertyContainer layout="table">
                        <PropertyDisplay
                            label="Description"
                            value={model.description}
                        />
                        <PropertyDisplay
                            label="Status"
                            value={
                                <div className="WorkItemDrawer__Status">
                                    <WorkItemStatusBadge
                                        status={model.context.workOrderItemState}
                                        badgeType="icon"
                                    />
                                    {WorkItemStatusDisplay.display(
                                        model.context.workOrderItemState,
                                    )}
                                </div>
                            }
                        />
                    </PropertyContainer>

                    <WorkItemStepList
                        className="WorkItemDrawer__WorkItemStepList"
                        steps={model.context.workOrderItemSteps}
                    />

                    <h3 className="WorkItemDrawer__SectionHeader">Options</h3>
                    {(optionsArray?.length ?? 0) > 0 ? (
                        <InfoGridTable>
                            {optionsArray?.map((opt, index) => (
                                <InfoGridRow
                                    key={index}
                                    label={opt.name}
                                    value={opt.value}
                                />
                            ))}
                        </InfoGridTable>
                    ) : (
                        <div className="WorkItemDrawer__SectionEmpty">&ndash;</div>
                    )}

                    <h3 className="WorkItemDrawer__SectionHeader">Label Variables</h3>
                    {(labelsArray?.length ?? 0) > 0 ? (
                        <PropertyContainer layout="table">
                            {labelsArray?.map((lbl, index) => (
                                <PropertyDisplay
                                    className="WorkItemDrawer__LabelDisplay"
                                    key={index}
                                    label={lbl.name}
                                    value={lbl.value}
                                />
                            ))}
                        </PropertyContainer>
                    ) : (
                        <div className="WorkItemDrawer__SectionEmpty">&ndash;</div>
                    )}
                </>
            )}
        </MyDrawer>
    );
}
