import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { WorkItemStepStatusDisplay } from 'features/workOrders/enums/WorkItemStepStatus';
import { computeWorkItemStepUiState } from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { WorkOrderItemStepHistory } from 'features/workstations/models/WorkOrderItemStepHistory';
import workstationsApi from 'features/workstations/workstations.api';
import Icons from 'Icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import WorkItemStepStatusBadge from '../WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import './WorkItemStepHistoryModal.scss';

const COLUMNS = ColumnBuilder<WorkOrderItemStepHistory>()
    .column({
        label: 'Date',
        key: 'timestamp',
        defaultSort: 'DESC',
        isSortable: true,
        getValue: item => item.timestamp,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Status',
        key: 'state',
        getValue: item => WorkItemStepStatusDisplay.display(item.state),
    })
    .column({
        label: 'User',
        key: 'user',
        getValue: item => `${item.context.principal.firstName} ${item.context?.principal.lastName}`,
    })
    .column({
        label: 'Workstation',
        key: 'workstation',
        emptyDash: true,
        getValue: item => item.context.workstation?.name,
    })
    .build();

export default function WorkItemStepHistoryModal({
    model,
    close,
}: {
    model: WorkItemStepBasic;
    close?: () => void;
}) {
    const query = workstationsApi.useWorkItemStepHistoryQuery(model.id);
    return (
        <MyModal
            className="WorkItemStepHistoryModal"
            close={close}
            containerSelector="#modal-root-top"
            mobileTitle="Work Item"
            header={
                <PageHeader
                    title="Work Step"
                    titleContext={model.context.workflowStep.name}
                />
            }
        >
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Work item"
                    value={model.context.workOrderItem.tuid}
                />
                <PropertyDisplay
                    label="Description"
                    value={model.context.workOrderItem.description}
                />
                <PropertyDisplay
                    label="Status"
                    value={
                        <div className="WorkItemStepHistoryModal__Status">
                            <WorkItemStepStatusBadge
                                uiState={computeWorkItemStepUiState(
                                    model.status,
                                    model.context.canTransition,
                                )}
                                lastTransitionReason={model.lastTransitionReason ?? null}
                                badgeType="badge"
                            />
                            {model.context.workstationLatest && (
                                <>
                                    <Icons.ArrowNext />
                                    <Link
                                        className="Link"
                                        to={`/workstations/${model.context.workstationLatest.id}/${model.id}`}
                                    >
                                        {model.context.workstationLatest?.name}
                                    </Link>
                                </>
                            )}
                        </div>
                    }
                />
            </PropertyContainer>

            <h2 className="WorkItemStepHistoryModal__HistoryTitle">History</h2>
            <DataTable
                className="WorkItemStepHistoryModal__HistoryTable"
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                columns={COLUMNS}
                onRefresh={query.refetch}
                isRefreshing={query.isFetching}
                emptyState="No history records found"
                zebra={true}
                showHeader={true}
            />
        </MyModal>
    );
}
