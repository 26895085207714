import z from 'zod';
import WorkstationPrintMethod from '../enums/WorkstationPrintMethod';
import WorkstationPrintOption from '../enums/WorkstationPrintOption';

export const WorkstationSchema = z.object({
    id: z.string(),
    name: z.string(),
    allowMultipleInProgress: z.boolean(),
    printOption: z.nativeEnum(WorkstationPrintOption),
    printMethod: z.nativeEnum(WorkstationPrintMethod),
});

export type Workstation = z.infer<typeof WorkstationSchema>;
