import MyButton from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import WorkOrderDetailModal from 'features/workOrders/components/WorkOrderDetailModal/WorkOrderDetailModal';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import WorkstationGoToItemModal from '../WorkstationGoToItemModal/WorkstationGoToItemModal';

/** Work order/item information that appears in the WorkstationStepDetailsModal */
export default function WorkstationStepSidebar({
    model,
    currentWorkstationId,
}: {
    model: WorkstationStepDetail;
    currentWorkstationId?: string;
}) {
    const dialogManager = useDialogManager();

    const showRelatedWorkItems = useCallback(async () => {
        if (currentWorkstationId) {
            await dialogManager.custom(WorkstationGoToItemModal, {
                stationId: currentWorkstationId,
                workItemTuid: model?.context.workOrderItem.tuid,
                closeOnSelect: true,
            });
        }
    }, [currentWorkstationId, dialogManager, model?.context.workOrderItem.tuid]);

    const [showWorkOrder, setShowWorkOrder] = useUrlQueryState('showWorkOrder', {
        allowedValues: ['', '1'],
    });

    return (
        <div className="WorkstationStepSidebar">
            <PropertyContainer>
                <PropertyDisplay
                    label="Work order"
                    value={
                        <a
                            className="Link"
                            onClick={() => setShowWorkOrder('1')}
                        >
                            {model.context.workOrder.tuid}
                        </a>
                    }
                />

                <PropertyDisplay
                    label="Related steps"
                    value={
                        <MyButton
                            buttonType="LinkButton"
                            onClick={showRelatedWorkItems}
                        >
                            Go to item
                        </MyButton>
                    }
                />

                <PropertyDisplay
                    label="Customer order"
                    value={model.context.workOrderItem.customerOrderReference}
                />

                <PropertyDisplay
                    label="Sidemark"
                    value={model.context.workOrderItem.customerOrderSidemark}
                />
            </PropertyContainer>

            {showWorkOrder && (
                <WorkOrderDetailModal
                    workOrderId={model.context.workOrder.id}
                    tuid={model.context.workOrder.tuid}
                    close={() => setShowWorkOrder('')}
                />
            )}
        </div>
    );
}
