import { WorkstationStepDetailsModal } from 'features/workstations/components/WorkstationStepDetailsModal/WorkstationStepDetailsModal';
import useStepsListNameParam from 'features/workstations/hooks/useStepsListNameParam';
import useStepsTableData from 'features/workstations/hooks/useStepsTableData';
import workstationsApi from 'features/workstations/workstations.api';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

/** Set to true then the details modal is opened by the router
 * If you stay on this route and navigate between steps, the modal will not animate with each transition
 * Once you close the modal, it will animate again when you open it
 */
let isNavigatingBetweenSteps = false;

export default function WorkstationStepDetailPage() {
    const { stationId, stepId } = useParams() as { stationId: string; stepId: string };

    useEffect(() => {
        // use small timeout to give the initial reveal animation time to run
        const timeout = setTimeout(() => {
            isNavigatingBetweenSteps = true;
        }, 300);

        return () => {
            clearTimeout(timeout);
            isNavigatingBetweenSteps = false;
        };
    }, []);

    return (
        // Use a separate comp so we can key it based on the stepId
        // this prevents cross-contamination of data when switching between steps
        <WorkstationStepDetailPagePure
            key={stepId}
            stationId={stationId}
            stepId={stepId}
        />
    );
}

function WorkstationStepDetailPagePure({
    stationId,
    stepId,
}: {
    stationId: string;
    stepId: string;
}) {
    const navigate = useNavigate();

    const query = workstationsApi.useGetWorkstationStepDetailQuery(
        {
            stepId,
            stationId,
        },
        {
            skip: !stepId || !stationId,
        },
    );
    usePageTitle(query.data?.context.workflowStep.name);

    const [listName] = useStepsListNameParam();

    const [listData] = useStepsTableData({
        workstationId: stationId,
        listName,
    });

    const currentListIndex: number = useMemo(
        () => listData.findIndex(item => item.id === stepId) ?? -1,
        [listData, stepId],
    );

    const [searchParams] = useSearchParams();

    const prevUrl = useMemo(() => {
        let prevId = '';
        // find the previous non-collapsed step
        for (let i = currentListIndex - 1; i >= 0; i--) {
            const step = listData[i];
            if (step && !step.isCollapsed) {
                prevId = step.id;
                break;
            }
        }
        return prevId ? `/workstations/${stationId}/${prevId}?${searchParams}` : undefined;
    }, [currentListIndex, stationId, listData, searchParams]);

    const nextUrl = useMemo(() => {
        let nextId = '';
        // find the next non-collapsed step
        for (let i = currentListIndex + 1; i < listData.length; i++) {
            const step = listData[i];
            if (step && !step.isCollapsed) {
                nextId = step.id;
                break;
            }
        }
        return nextId ? `/workstations/${stationId}/${nextId}?${searchParams}` : undefined;
    }, [currentListIndex, stationId, listData, searchParams]);

    return stepId ? (
        <>
            <WorkstationStepDetailsModal
                model={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                currentWorkstationId={stationId}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                animateIn={!isNavigatingBetweenSteps}
                close={() => navigate(`..?${searchParams}`)}
            />
        </>
    ) : null;
}
